import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import moment from "moment";
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Image from '../../Images/Banner-image.svg'
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw'
function ArticleList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const [trending, setTrending] = useState([]);
  const [trendingList, setTrendingList] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    getTrendingList();
    getArticle();
  
  }, [])
  useMemo(() => getTrendings(trending), [trending]);
  function getArticle(){
    console.log("api")
    setOpen(true);
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/category/articles').then(
      (response) => {
        setArticle(response?.data?.data);
        setOpen(false);
      },
      (error) => {
        setOpen(false);
        console.log(error)
      }
    );
  }
  function viewDetail(e,id){
    e.preventDefault();
    navigate("/view/" + id);
  }
  function viewAll(e,data){
    e.preventDefault();
    navigate("/category/" + data);
  }
  function getTrendingList(){
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/trending-article').then(
      (response) => {
       
        setTrending(response?.data?.data);
       
      },
      (error) => {
        setOpen(false);
        console.log(error)
      }
    );
  }
  function getTrendings(){
    setTrendingList(trending);
    console.log(trending,"treading")
    console.log(trendingList,"trendingList")
  }
  function dateDiffInDays(b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const a = new Date();
    console.log(b)
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(new Date(b).getFullYear(), new Date(b).getMonth(), new Date(b).getDate());
    console.log(Math.floor((utc2 - utc1) / _MS_PER_DAY))
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  return(
    <>
      <div id="carouselExampleIndicators" className="carousel slide pb-4" data-bs-ride="carousel">
      {trending?.length !== 0 ? (<>
    <div className="carousel-inner">
      <Carousel showArrows={false} autoPlay={true} swipeable={true} useKeyboardArrows={true} emulateTouch={true} infiniteLoop={true} dynamicHeight={true} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>

      {trending.map((row,index) =>
     { return ( <><div className="carousel-item active">
      <div className="top-banner middle-toparea" style={{backgroundImage : `url(${row?.article?.image?.url !== undefined &&
      row?.article?.image?.url !== null ? row?.article?.image?.url :Image})`}}>
         <div className='side-bg'>
          <div className="container-fluid padding-body">
           

           
            <div className="banner-top col-md-5 slide-top">
              <div className="mt-3 mb-3 mt-5">
              <div className='row'>
                  <div className='col-6 text-start'>
                <div className="sub-text2 text-start">{t('breadcurmb')} /<span style={{"cursor":"pointer"}} onClick={e=>viewAll(e,row?.article?.category?.id)}> {row?.article?.category?.title} </span> </div>
                </div>
                  <div className='col-6 text-end'>
               <button className="Trending-btn"><span>{t('trending')}</span></button>
               </div>
                </div>
            </div>
              <h3 className="mb-4 article-slide text-start pb-2"> <span className='heading-text'>{row?.article?.title}</span></h3>
              <div className='caroual-p-card' >
               <p className="top-paragrm text-start table-text ">
                {/* {row?.article?.content} */}
                <ReactMarkdown  className="react-markdown-carousel" children={row?.article?.content}  remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} disallowedElements={"img ,table ,iframe"} />
                </p> 
                </div>
                <div className="mt-3 mb-3 mt-5">
                <div className='row'>
                  <div className='col-8 text-start'>
                 <p className="time-class"> 
                 {/* {row?.attributes?.article?.data?.attributes?.createdAt !== undefined && row?.attributes?.article?.data?.attributes?.createdAt !== null ?
                  (<>{moment.utc(new Date(row?.attributes?.article?.data?.attributes?.createdAt)).fromNow()}</>) : (<></>) }  */}

                 {/* {row?.article?.createdAt !== undefined && row?.article?.createdAt !== null ?
                (<>  {dateDiffInDays(row?.article?.createdAt) === 0 ? (<>{moment.utc(new Date(row?.article?.createdAt)).local(true).fromNow()}</>)
                 : (<>{ moment.utc(row?.article?.createdAt).format("DD/MM/YYYY hh:mm a")}</>)}
                 </> ): (<></>)}   */}
                  </p>
                  </div>
                  <div className='col-4 text-end'>
                  <button className="detailss-btn" onClick={e => viewDetail(e,row?.article?.id)}>{t('details')}</button>
                  </div>
                </div>
              </div>
             
            </div>
            </div>
          </div>
        </div>
      
      </div></>)
       })}
      </Carousel>
     
    </div></> ): <> <div className="pbcarousel-5"></div></>}
    
  </div>



  <div className="clearfix"></div>
<div className="container-fluid p-0">


{article.map((row,index) =>
 { return (
  <>{row?.articles?.length !== 0 ?  <section id="Company">
  <div className="padding-body">
    
      <div className="mt-3 mb-5 d-flex justify-content-between display-table-cell pb-3">
        <div className="article-heading ">
          <h4>{row?.title}</h4>
        </div>
        <button className="article-buttons button-display-none-up" onClick={e=>viewAll(e,row?.id)}>{t('view_article')}</button>
    </div>
   

    <div className="row">
    {row?.articles?.map((article,index1) =>  { 
     return( <>
     {index1 === 0? <div className="col-md-6 table-view mb-3">
     <div className="card Card-blog card-table-view">
       <div className="blog-whitebg">
         <img  src={article?.image?.url !== undefined && article?.image?.url !== null ?
         article?.image?.url : Image} className="article-img-top" alt="..." />
         <div className="blog-heading px-4 py-3">
           <p className="read-more article-heading-text" >{row?.title}</p>
         </div>
       </div>

       <div className="card-body pb-5 blog-content">
         <div className="blog-head  mb-2 select-point" onClick={e => viewDetail(e,article?.id)}><span className='heading-text'>{article?.title}</span> </div>
       
         <p className="card-text article-description-text p-card">
          {/* {article?.content} */}
          <ReactMarkdown  className="react-markdown-test"  children={article?.content} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} disallowedElements={"img ,table ,iframe"} />
          </p>
         

           <div className="d-flex justify-content-between mt-3">
             <p className="read-more pt-3">
             {article?.createdAt !== undefined && article?.createdAt !== null ?
              (<>{moment.utc(article?.createdAt).format("DD/MM/YYYY hh:mm a")}</>) : (<></>)} </p>
             <span className='select-point' onClick={e => viewDetail(e,article?.id)}><i className="fa-solid fa-arrow-right-long ps-3 pt-4"></i></span>
          </div>
       </div>
     </div>
      </div> : <></>}
     </>
     )  })}
      <div className="col-md-6">
      {row?.articles?.map((articleR,indexR) =>  { 
     return(
      <>
      {indexR === 1 || indexR === 2? <>
      <div className="row">
        <div className="col-md-4">
          <div className="article-side"><img src={articleR?.image?.url !== undefined && articleR?.image?.url !==null ?
          articleR?.image?.url : Image}  alt="" /></div>
        </div>
        <div className="col-md-8">
          <p className="read-more article-heading-text">{row?.title}</p>
          <h5 className="py-2 pb-3 select-point" onClick={e => viewDetail(e,articleR?.id)}><span className='heading-text'>{articleR?.title}</span></h5>
          <p className="card-text article-description-text  p-card">
            {/* {articleR?.content} */}
            <ReactMarkdown  className="react-markdown-test"  children={articleR?.content} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} disallowedElements={"img ,table ,iframe"} />
            </p>
            <p className="read-more pt-2">
              {articleR?.createdAt !== undefined && articleR?.createdAt !== null ? 
               (<>{ moment.utc(articleR?.createdAt).format("DD/MM/YYYY hh:mm a")}</>) : (<></>) }
              
            </p>

        </div>
       </div>
       <hr />
       </>:<></>}
       </> )  })}

      </div>
  

    </div>

    <button className="article-buttons button-display-none-down" onClick={e=>viewAll(e,row?.id)}>{t('view_article')}</button>
 
  </div>
</section>: <></>}
  
   </>
) })}
<div className="clearfix"></div>


</div>
<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default ArticleList;
