import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Image from '../../Images/Banner-image.svg'
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw'
moment.locale('ja', {
  relativeTime : {
      future : '%s in',
      past : '前 %s',
      s : '数秒',
      ss : '秒 %d',
      m : '数分',
      mm : '分 %d',
      h : '数時間',
      hh : '時間 %d',
      d : '数日',
      dd: "%d days",
      w:  "a week",
      ww: "%d weeks",
      M:  "a month",
      MM: "%d months",
      y:  "a year",
      yy: "%d years"
  },
  // meridiemParse : /AM|PM/,
  // isPM : function (input) {
  //     return input.charAt(0) === 'M';
  // },
  // meridiem : function (hours, minutes, isLower) {
  //     return hours < 12 ? 'AM' : 'PM';
  // },
});
function Article(){
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [trending, setTrending] = useState([]);
  const [mainarticles, setMainArticles] = useState([]);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    getArticleBySearch();
    getTrendingList();
  }, [params.page])
  function getArticleBySearch(){
    // axios.get(process.env.REACT_APP_BASE_URL + 'articles?filters[category][title][$eq]='+ params.page + '&pagination[page]=1&pagination[pageSize]=4&populate=*')
   // axios.get(process.env.REACT_APP_BASE_URL + 'articles?filters[category][id][$eq]='+ params.page).then(
   axios.get(process.env.REACT_APP_BASE_URL + 'articles?[filters][category][id][$eq]='+params.page+ '&populate[0]=category&populate[1]=image&sort[0]=updatedAt%3Adesc').then(
   (response) => {

        setMainArticles(response?.data?.data);
        console.log(mainarticles,"main")
      },
      (error) => {
        console.log(error)
      }
    );
  }
  function getTrendingList(){
    setOpen(true);
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/trending-article').then(
      (response) => {
        setTrending(response?.data?.data);
        setOpen(false);
      },
      (error) => {
        console.log(error)
      }
    );
  }
  function viewDetail(e,id){
    e.preventDefault();
    navigate("/view/" + id);
  
  }
  function viewAll(e,data){
    e.preventDefault();
    navigate("/category/" + data);
  }
  function dateDiffInDays(b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const a = new Date();
    console.log(b)
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(new Date(b).getFullYear(), new Date(b).getMonth(), new Date(b).getDate());
    console.log(Math.floor((utc2 - utc1) / _MS_PER_DAY))
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
  
  // // test it
  // const a = new Date("2017-01-01"),
  //     b = new Date("2017-07-25"),
  //     difference = dateDiffInDays(a, b);
  return(
    <>
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  {trending?.length !== 0 ? (<>
    <div className="carousel-inner">
    <Carousel showArrows={false} autoPlay={true} swipeable={true} useKeyboardArrows={true} emulateTouch={true} infiniteLoop={true} dynamicHeight={true} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50}>
   
      {trending.map((row,index) =>
     { return ( <div className="carousel-item active">
      <div className="top-banner middle-toparea"  style={{backgroundImage : `url(${row?.article?.image?.url  !== undefined &&
      row?.article?.image?.url !== null ? row?.article?.image?.url : Image})`}}>
           <div className='side-bg'>
          <div className="container-fluid padding-body">
        
            <div className="banner-top col-md-5 slide-top">
              <div className="mt-3 mb-3  mt-5">
                <div className='row'>
                  <div className='col-6 text-start'>
                  <div className="sub-text2 text-start"><Link className="sub-text2 text-decoration-none" to={"/"}>{t('breadcurmb')}</Link> /<span style={{"cursor":"pointer"}} onClick={e=>viewAll(e,row?.article?.category?.id)}> {row?.article?.category?.title} </span> </div>
              
                  </div>
                  <div className='col-6 text-end'>
                  <button className="Trending-btn"><span>{t('trending')}</span></button>
                  </div>
                </div>
               
            </div>
              <h3 className="mb-4 article-slide text-start"><span className='heading-text'>{row?.article?.title}</span></h3>
              <div className='caroual-p-card' >
               <p className="top-paragrm text-start table-text">
                {/* {row?.article?.content} */}
                <ReactMarkdown  children={row?.article?.content} className="react-markdown-carousel" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} disallowedElements={"img ,table ,iframe"} />
                </p> 
              </div>
                {/* <div className="mt-3 mb-3 d-flex justify-content-between mt-5">
               
              </div> */}
              <div className="mt-3 mb-3  mt-5">
                <div className='row'>
                  <div className='col-8 text-start'>
                  {/* <p className="time-class">
              {row?.article?.createdAt !== null && row?.article?.createdAt !== undefined ?
                (<>  {dateDiffInDays(row?.article?.createdAt) === 0 ? (<>{moment.utc(new Date(row?.article?.createdAt)).local(true).fromNow()}</>)
                 : (<>{ moment.utc(row?.article?.createdAt).format("DD/MM/YYYY hh:mm a")}</>)}
                 </> ): (<></>)}  
               
              
                 </p> */}
                  </div>
                  <div className='col-4 text-end'>
                  
                  <button className="detailss-btn" onClick={e => viewDetail(e,row?.article?.id)}>{t('details')}</button>
                  </div>
                </div>
               
            </div>
            </div>
          </div>
          </div>
        </div>
      
      </div>)
       })}
      </Carousel>
    </div></> ): <> <div className="pbcarousel-5"></div></>}
    
  </div>


  

  <div className="clearfix"></div>

  <div className="container-fluid p-0">

    <section id="Company">
      <div className="padding-body">
        
         {mainarticles?.length !== 0 ? ( <div className="mt-3 mb-5 d-flex justify-content-between">
            <div className="article-heading ">
              <h4>{mainarticles[0]?.attributes?.category?.data?.attributes?.title}</h4>
            </div>
        </div>) : (<></>)}
       

        <div className="row">
          
        {mainarticles.map((row,index) =>
        { return ( <> 
        {index === 0 || index === 1 || index === 2 || index === 3  ? <div className="col-md-3 col-article-3 table-view mb-2" key={index}>
            <div className="card Card-blog  mb-3 card-table-view position-relative">
              <div className="blog-whitebg">
                <img src={row?.attributes?.image?.data?.attributes?.url !== undefined && row?.attributes?.image?.data?.attributes?.url !== null ? row?.attributes?.image?.data?.attributes?.url :Image} className="article-img-top" alt="..." />
                <div className="blog-heading px-4 py-3">
                  <p className="read-more article-heading-text">{row?.attributes?.category?.data?.attributes?.title}</p>
                </div>
              </div>

              <div className="card-body pb-4 p-leftt-article">
                <div className="blog-head1 pb-4 select-point" onClick={e => viewDetail(e,row?.id)}><span className='category-heading-text'>{row?.attributes?.title}</span></div>
                <p className="card-text  mb-5 category-description-text p-card">
                  {/* {row?.attributes?.content} */}
                  <ReactMarkdown  children={row?.attributes?.content} className="react-markdown-test" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} disallowedElements={"img ,table ,iframe"} />
                  </p>
              
                </div>


                 <div class="footer-card p-leftt-article">
               
                 <div className="d-flex justify-content-between mt-3">
                    <p className="read-more pt-3">
                      {row?.attributes?.createdAt !== undefined && row?.attributes?.createdAt !== null ? 
                     (<>{ moment.utc(row?.attributes?.createdAt).format("DD/MM/YYYY hh:mm a")}</>) : (<></>)}
                      
                    </p>
                    <span className='select-point' onClick={e => viewDetail(e,row?.id)}><i className="fa-solid fa-arrow-right-long ps-3 pt-4"></i></span>
                 </div>
              </div>
            </div>
          </div> : <></>} 
          </> )
       })}
          

      

        </div>

     
      </div>
    </section>

    <div className="clearfix"></div>

      <section id="Company" className="top-90">
        <div className="padding-body">
    
          <div className="row">
          {mainarticles.map((row,index) =>
           { return ( <> 
          {index !== 0 && index !== 1 && index !== 2 && index !== 3  ?  
          <div className="col-md-6">
                <div className="row select-row">
                 <div className="col-md-4">
                   <div className="article-side"><img src={row?.attributes?.image?.data?.attributes?.url !== undefined && row?.attributes?.image?.data?.attributes?.url !== null ? row?.attributes?.image?.data?.attributes?.url :Image} alt="" /></div>
                 </div>
                 <div className="col-md-8">
                   <p className="read-more article-heading-text">{row?.attributes?.category?.data?.attributes?.title}</p>
                   <h5 className="py-2 pb-3 select-point"  onClick={e => viewDetail(e,row?.id)}> <span className='category-heading-text'>{row?.attributes?.title}</span></h5>
                   <p className="card-text category-description-text p-card">
                    {/* {row?.attributes?.content} */}
                    <ReactMarkdown  children={row?.attributes?.content}  className="react-markdown-test"  remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} disallowedElements={"img ,table ,iframe"} />
                    </p>
                     <p className="read-more pt-2">
                      {row?.attributes?.createdAt !== undefined && row?.attributes?.createdAt !== null ?
                      (<>{moment.utc(row?.attributes?.createdAt).format("DD/MM/YYYY hh:mm a")}</>) : (<></>)}
                      
                    </p>
     
                 </div>
                </div>
                <hr />

                  
         
           </div>: <></>} 
               </> )
          })}
  
          </div>
          
       
        </div>
      </section>
  
      <div className="clearfix"></div>



  </div>
  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Article;
