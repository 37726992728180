import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import moment from "moment";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ImageD from '../../Images/Banner-image.svg'
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw'
function ArticleView() {
    const { t } = useTranslation();
    const [articleDetails, setArticleDetails] = useState([]);
    const [popularArticle, setPopularArticle] = useState([]);
    const params = useParams();
    // const [currentPage, setCurrentPage] = useState(parseInt(params.id));
    const [image, setImage] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        getArticleById();
        getPopularArticle();
      }, [params.id])
      function getArticleById(){
        axios.get(process.env.REACT_APP_BASE_URL + 'articles/' + params.id + '?populate=*').then(
            (response) => {
              console.log(response);
              setArticleDetails(response?.data?.data?.attributes)
              console.log("articleDetails",articleDetails)
              if(response?.data?.data?.attributes?.image?.data?.attributes?.url !== undefined && response?.data?.data?.attributes?.image?.data?.attributes?.url !== null){
                setImage(response?.data?.data?.attributes?.image?.data.attributes?.url)
              }else {
                setImage(null)
               // setImage(ImageD)
              }
            
            },
            (error) => {
              setOpen(false);
              console.log(error)
            }
          );
      }
      function getPopularArticle(){
       // setPopularArticle([])
        setOpen(true);
        axios.get(process.env.REACT_APP_BASE_URL + 'filtered/popular-article' ).then(
            (response) => {
              console.log(response);
              // if(response?.data?.data.length !== 0){
              //   for (const iterator of response?.data?.data) {
              //     if(iterator?.attributes?.article?.data !== null){
              //       setPopularArticle(popularArticle => [iterator, ...popularArticle]);
              //     }
              //   }
              // }
              setPopularArticle(response?.data?.data)
              setOpen(false);
            },
            (error) => {
              setOpen(false);
              console.log(error)
            }
          );
      }
      function viewDetail(e,id){
        e.preventDefault();
        navigate("/view/" + id);
      }
  return(
   <>
       <section id="Hotjobs" className="top-90">
        <div className="padding-body">
            <div className="sub-text pt-4"><Link className="sub-text2 text-decoration-none" to={"/"}>{t('breadcurmb')}</Link> /  <span>{t('article_detail')}</span> </div>
    
            <div className="top-90">
                <div className="row mb-5 justify-content-between">

                    

                    <div className="col-md-9 custom-col9">
                         <div className="row">
                            <div className="card job-list-card p-0">
                                <div className="card-body blog-body ">
                                    <div className="row">
                                        <div className="col-md-12 p-4">
                                      
                                               <h5 className="my-4">{articleDetails.title}</h5>
                                        </div>
                                          <div className="p-0">
                                           {image !== null ? (<img  src={image} alt="" className="blog-img" />) :( <></>)} 
                                          </div>
                                          <div className="col-md-12 p-4 mt-5">
                                            {/* <div className="popup-head mt-3 mb-3 d-flex justify-content-between"> */}
                                               {/* <h6>{t('description_heading')}</h6> */}
                                                <p className="text-end">    
                                                {articleDetails.createdAt !== undefined && articleDetails.createdAt !== null ? 
                                                (<>{ moment.utc(articleDetails.createdAt).format("DD/MM/YYYY hh:mm a")}</>) : (<></>)} 
                                                 </p>
                                            {/* </div> */}


                                            <p className="mt-4">
                                            <ReactMarkdown  children={articleDetails.content} className="react-markdown-detail" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} />
                                            
                                              {/* {articleDetails.content} */}
                                              </p>

                                               


                                                    {/* <div className="row">
                                                        <div className="col-md-6">
                                                            <img src={require("../../Images/blog-img1.png")} className="img-fluid" alt="" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            {articleDetails.description}
                                                        </div>
                                                    </div> */}
                                        </div>
                              

                                    </div>
                                </div>
                            </div>

                         </div>

                    </div>
                   
                    <div className="col-md-3 custom-col3 ">

                       <div className="row">
                       {popularArticle?.length !== 0 ? <div className="mt-3 mb-5 d-flex justify-content-between">
                            <div className="article-heading ">
                              <h4 className="font-16">{t('popular_aticle')}</h4>
                            </div>

                        </div> : <></>}
                       
                       </div>

                       {popularArticle.map((row,index) =>
                         { return (
                         <> 
                         <div className="row mb-2 mt-2">
                           <div className="row">
                        <div className="col-md-4 custom-md4">
                            <div className="article-small"><img src={row?.article?.image?.url !== undefined && row?.article?.image?.url !== null ?
                            row?.article?.image?.url :ImageD} alt="" /></div>
                        </div>
                        <div className="col-md-8 custom-md8">
                            <p className="read-more font-14 article-heading-text">{row?.article?.category?.title}</p>
                            <div className="py-2 pb-3 fnt-14-v small-h3 select-point"  onClick={e=>viewDetail(e,row?.article?.id)}><span className='heading-text-popular '>{row?.article?.title}</span></div>
                            <p className="read-more pt-2 font-14">
                             {row?.article?.createdAt !== undefined && row?.article?.createdAt !== null ?
                             (<>{ moment.utc(row?.article?.createdAt).format("DD/MM/YYYY hh:mm a")}</>) : (<></>)} 
                            </p>
            
                   

                        </div>
                        </div>
  
                      </div>
                       <div className="border-bottom-article"></div></>)
                     })}
                     

                     
                    </div>
                 
                </div>

            </div>
        </div>
    </section>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
   </>
  );
}

export default ArticleView;
