import React from 'react'
import { BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import ArticleList from './components/article-list/article-list';
import ArticleSearch from './components/article-search/article-search';
import ArticleView from './components/article-view/article-view';
import Article from './components/article/article';
import Error from './components/error/error';
import Footer from './components/footer/footer';
import Home from './components/home/home';
function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}>
           <Route path="" element={<ArticleList />} /> 
           <Route path="view/:id" element={<ArticleView />} /> 
           <Route path="category/:page" element={<Article />} /> 
           <Route path="search"  element={<ArticleSearch />} /> 
         
        </Route>
        <Route path="error" element={<Error />} />
        <Route path='*' exact={true} element={<Error />}  />
    </Routes>

   </Router>


   
    </div>
  );
}

export default App;
